/* istanbul ignore file -- api */
import { fetchGraphql } from '@ori/fetching';
import { resolveHideRating } from '@ori/product-box';

import { GetFrontPageQuery, GetFrontPageQueryVariables } from '../models';
import { frontPageQuery } from '../queries';
import type { GetFrontpageDataParams } from './types';

/**
 * Fetch function that fetches data for the frontpage.
 */
export const getFrontpageData = async ({ graphQlNetUrl, staticApiUrl, headers, tenant }: GetFrontpageDataParams) => {
  const [data, hideRating] = await Promise.all([
    fetchGraphql<GetFrontPageQuery, GetFrontPageQueryVariables>({
      query: frontPageQuery,
      url: graphQlNetUrl,
      headers,
    }),
    resolveHideRating({ staticApiUrl, tenant }),
  ]);

  return { hideRating, data };
};
