import { Button, styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { AlignmentTypes } from '../../../../types';

export const Root = styled(Button, { shouldForwardProp: shouldForwardProp(['color', 'variant']) })<{
  alignment: AlignmentTypes;
}>(({ theme, alignment }) => ({
  fontSize: '1.4rem',
  height: '45px',
  textAlign: 'center',
  gridArea: 'title',
  alignSelf: 'center',
  justifySelf: 'center',
  margin: theme.spacing(0, 'auto'),
  padding: theme.spacing(3, 6),
  order: 4,
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'end',
    justifySelf: alignment === 'left' ? 'start' : 'end',
    margin: 0,
    order: 'unset',
  },
}));
